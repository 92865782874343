<template>
  <div>
    <div class="flex-col items-center section_3">
      <video
        id="index_video"
        class="image_36"
        src="@/assets/video/pinpai-1.mp4"
        style="object-fit: cover; width: 100%; height: 100%"
        x5-playsinline="true"
        webkit-playsinline="true"
        playsinline="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-ignore-metadata="true"
        autoplay=""
        muted=""
        loop=""
      ></video>
      <div class="flex-col group_7">
        <div class="group_8">
          <span class="font_6" style="color: black">
            我们的使命是创造卓越的高性能产品
            <br />
          </span>
          <span class="font_6" style="color: black"><strong>赢得全世界消费者的心</strong></span>
        </div>
        <span @click="play" class="font_7 text_8" style="color: black"
          >播放视频</span
        >
        <div class="section_4"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Vue3New",

  data() {
    return {};
  },
  methods: {
    play() {
      this.$bus.$emit("playvideo", {
        show: true,
        url: require("@/assets/video/band1.mp4"),
      });
    },
  },
};
</script>
<style scoped>
.section_3 {
  /* padding: 369px 0; */
  height: 100vh;
  justify-content: center;
}
.image_6 {
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  position: absolute;
}
.group_7 {
  width: 769px;
  position: relative;
  top:-500px;
}
.group_8 {
  text-align: center;
}
.font_6 {
  font-size: 48px;
  font-family: Albbr;
  line-height: 72px;
  color: #ffffff;
}
.font_7 {
  font-size: 20px;
  font-family: Albb;
}
.text_8 {
  margin-top: 53px;
  align-self: center;
  color: #ffffff;
  line-height: 19px;
  cursor: pointer;
}
.section_4 {
  margin-top: 5px;
  align-self: center;
  background-color: #000;
  width: 76px;
  height: 3px;
}
</style>
